import React from 'react';
import Layout from '../components/Layout';
import ReactFullpage from '@fullpage/react-fullpage';
import {
  MainSection,
  DetailsSection
} from '../components/Website';
import { graphql } from 'gatsby';
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const pluginWrapper = () => {
  require('fullpage.js/vendors/scrolloverflow');
};
const timeout = 1000;

class Website extends React.Component {

  render() {
    const { data } = this.props;
    return (
      <Layout location={this.props.location} title="Website">
        <head>
          <title>{`Website | ${data.site.siteMetadata.title}`}</title>
          <link rel="canonical" href={`${data.site.siteMetadata.url}/website/`} />
        </head>
        <TransitionGroup>
          <ReactTransition
            key={'/website'}
            timeout={{
              enter: timeout,
              exit: timeout,
            }}
          >
            <ReactFullpage
              pluginWrapper={pluginWrapper}
              scrollOverflow
              verticalCentered={false}
              afterRender={() => {
                window.dispatchEvent(new Event("hideHeaderBg"));
              }}
              onLeave={(index, nextIndex, direction) => {
                window.dispatchEvent(new Event(nextIndex.index ? "showHeaderBg" : "hideHeaderBg"))
              }}
              render={({ state, fullpageApi }) => {
                return (
                  <ReactFullpage.Wrapper>
                    <MainSection />
                    <DetailsSection />
                  </ReactFullpage.Wrapper>
                );
              }}
            />
          </ReactTransition>
        </TransitionGroup>
      </Layout>
    );
  }
}

export default Website;

export const query = graphql`
  query WebsitePageQuery {
    site {
      siteMetadata {
        title
        url
      }
    }
  }
`;
