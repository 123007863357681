import React from 'react';
import Grid from '@material-ui/core/Grid';
import websitePageStyles from '../website.module.css';
import sectionStyles from './index.module.css';

class MainSection extends React.Component {
  render() {
    return (
      <section className={`${websitePageStyles.mainSection} section`}>
        <Grid container spacing={0} alignItems="center" className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item xs={12}>
            <div className={`${sectionStyles.articleContainer} m-auto`}>
              <div className={`${sectionStyles.sectionTitle} sectionTitle`}>Website Design Services</div>
              <div className={`${sectionStyles.sectionTitleUnderline} sectionTitleUnderline ul-center`} />
              <div className={`${sectionStyles.articleBigTitle} articleBigTitle`}>
                Is your website responsive for whatever device your customer is using?
                <br />
                How Secure is your site?
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default MainSection;