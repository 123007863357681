import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

import paintImage from '../../../../static/assets/img/image/website/Web design 02-image 02.jpg';
import laptopImage from '../../../../static/assets/img/image/website/Web design 2p image 01.jpg';

class DetailsSection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`}>
        <img className={`${sectionStyles.topRightPaintImage}`} src={paintImage} alt="" />
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item lg={6} xs={12}>
                <div className="articleTitleWithNumberContainer">
                  <div className={`articleTitleNumber float-left`}>
                    01
                  </div>
                  <div className={`${sectionStyles.sectionTitleContainer} float-left`}>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle`}>
                      Web Design
                    </div>
                    <div className={`sectionTitleUnderline ul-thin`} />
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} xs={12}>
                <div className={`${sectionStyles.articleTitle} articleTitle`}>
                  At Mimosa Solutions our goal is to provide high quality work and a simplified solution for you.
                </div>
                <p className={`${sectionStyles.generalArticle} generalArticle`}>
                  Rather than spending time updating your website and spending time dealing with design templates that don't quite fit your business, leave the design and coding to us. We will create a site that looks great and doesn't break the bank.
                </p>
                <p className={`${sectionStyles.generalArticle} generalArticle`}>
                  Our Web Designers will work with you to create custom, professional, cutting edge webpage layouts that will impress your customers. You provide the content, image and URL and we will do the rest. All websites we create are responsive so that they look great on desktop, tablet or mobile. Hosting on a https secure server is included when you sign-up for our services. Our team will also maintain and update your site for you.
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item lg={6} xs={12}>
                <div className="articleTitleWithNumberContainer">
                  <div className={`articleTitleNumber float-left`}>
                    02
                  </div>
                  <div className={`${sectionStyles.sectionTitleContainer} float-left`}>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle`}>
                      Integrated Lead Generation
                    </div>
                    <div className={`sectionTitleUnderline ul-thin`} />
                  </div>
                </div>
                <Hidden mdDown>
                  <div className={`${sectionStyles.laptopWithPaintImageContainer} float-left`}>
                    <img className={`${sectionStyles.laptopWithPaintImage}`} src={laptopImage} alt="" />
                  </div>
                </Hidden>
              </Grid>
              <Grid item lg={6} xs={12}>
                <div className={`${sectionStyles.articleTitle} articleTitle`}>
                  Want to get more customers from your website?
                </div>
                <p className={`${sectionStyles.generalArticle} generalArticle`}>
                  Our Integrated Lead Generating Form not only captures contact information from your website visitors, but it integrates with our M3 Platform so that you can send Email/SMS messages and better track your prospect customers. Our platform will store your prospect contacts in one place and recrod opt-in consent records for you.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DetailsSection;